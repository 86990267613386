import { Routes } from '@angular/router';
import { UiTemplateComponent } from '@core';
import { Environment, Role } from '@domains';
import { AuthGuard, CharityFeatureGuard, OnboardingGuard, RoleGuard } from '@rspl-auth';
import { HealthCheckComponent } from '@rspl-ui';

export const appRoutes = (environment: Environment) =>
  [
    {
      path: '',
      component: UiTemplateComponent,
      children: [
        {
          path: '',
          canActivate: [AuthGuard, OnboardingGuard, RoleGuard],
          data: { roles: [Role.CHARITY, Role.STORE] },
          loadChildren: () => import('@donations').then((module) => module.donationsRoutes(environment)),
        },
        {
          path: 'onboarding',
          loadChildren: () => import('@onboarding').then((module) => module.onboardingRoutes(environment)),
        },
        {
          path: 'stores',
          canActivate: [AuthGuard, OnboardingGuard, RoleGuard],
          data: { roles: [Role.CHARITY, Role.STORE] },
          loadChildren: () => import('@stores').then((module) => module.storeRoutes(environment)),
        },
        {
          path: 'users',
          canActivate: [AuthGuard, OnboardingGuard, RoleGuard],
          data: { roles: [Role.CHARITY] },
          loadChildren: () => import('@users').then((module) => module.usersRoutes(environment)),
        },
        {
          path: 'settings',
          canActivate: [AuthGuard, OnboardingGuard, RoleGuard],
          data: { roles: [Role.CHARITY] },
          loadChildren: () => import('@charities').then((module) => module.charitiesRoutes(environment)),
        },
        {
          path: 'sponsorship',
          canActivate: [AuthGuard, OnboardingGuard, RoleGuard, CharityFeatureGuard],
          data: {
            roles: [Role.CHARITY],
            features: ['discount'],
            shouldAllowFeatures: true,
          },
          loadChildren: () => import('@discounts').then((module) => module.discountsRoutes(environment)),
        },
      ],
    },
    {
      path: 'hc',
      component: HealthCheckComponent,
    },
    {
      path: '**',
      redirectTo: '/',
    },
  ] as Routes;
